<template>
    <div>
        <List
            :columns="columns"
            :get_table_list="get_table_list"
            @handle_details_click="handle_details_click"
            :form_data_seo.sync="form_data_seo"
			:submit_preprocessing.sync="submit_preprocessing"
        >
			<template slot="right_btn">
                <a :href="'/manage/crm.export/cost' | export_form" v-if="this.$store.state.page_auth.export" target="_blank">
					<a-button  class="a_btn">导出</a-button>
				</a>
            </template>
		</List>
        <RightDrawer :visible.sync="visible">
            <template slot="content">
                <ApprovalHeader :details="details"></ApprovalHeader>
                <a-divider />
                <a-descriptions>
                    <a-descriptions-item :span="3" label="所属部门">{{details.department}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="岗位职务">{{details.role}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="审批编号">{{details.id}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="申请客户">{{details.customer.name}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="联系方式">{{details.customer.contact_phone}}</a-descriptions-item>
                    <a-descriptions-item
                        :span="3"
                        label="门店地址 "
                    >{{details.customer.province + details.customer.city + details.customer.district + details.customer.address}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="开始时间">{{details.start_time}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="结束时间">{{details.end_time}}</a-descriptions-item>
                    <!-- <a-descriptions-item :span="3" label="申请时长">{{details.type}}</a-descriptions-item> -->
                    <a-descriptions-item :span="3" label="申请事由">{{details.desc}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="图片">
                        <!-- <img class="leave_img" src alt /> -->
                    </a-descriptions-item>
					<a-descriptions-item :span="2">
					    <LookImages v-if="details.leave_img" :list="details.leave_img.split(',')"></LookImages>
					</a-descriptions-item>
                </a-descriptions>
                <a-table :columns="columns_details" :pagination="false" :dataSource="details.goods">
                    <template slot="footer" slot-scope>
                        <div
                            style="text-align:right"
                        >共{{details.goods_num}}件商品，总计：¥{{details.tatol_price}}</div>
                    </template>
                </a-table>

                <!-- <a-divider /> -->

                <div class="drawer_title">审批进度</div>
                <ApprovalSpeed :list="details.approver"></ApprovalSpeed>
                <div class="drawer_title" v-if="details.cclist.length">抄送</div>
                <ApprovalCc :list="details.cclist"></ApprovalCc>
            </template>
        </RightDrawer>
    </div>
</template>

<script>
import List from './components/list'
import { costList, costInfo } from '@/api/approval'
import RightDrawer from "@/components/RightDrawer";
import ApprovalSpeed from "@/components/ApprovalSpeed";
import ApprovalCc from "@/components/ApprovalCc";
import ApprovalHeader from "@/components/ApprovalHeader";
import LookImages from "@/components/LookImages";
const columns_details = [
    {
        title: "品项名称",
        dataIndex: "goods_title",
    },
    {
        title: "数量",
        dataIndex: "num",
    },
    {
        title: "押金",
        dataIndex: "pay_price",
    },
];

const columns = [
    {
        title: "审批编号",
        dataIndex: "id",
    },
    {
        title: "标题",
        dataIndex: "title",
    },
    {
        title: "部门",
        dataIndex: "path_name",
    },
    {
        title: "申请客户",
        dataIndex: "name",
    },
    {
        title: "物料名称",
        dataIndex: "goods_title",
    },
    {
        title: "押金合计",
        dataIndex: "tatol_price",
    },
    {
        title: "申请事由",
        dataIndex: "desc",
    },
    {
        title: "审批状态",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        }
    },
    {
        title: "操作",
        width: 150,
        scopedSlots: {
            customRender: "operation"
        }
    },
];

export default {
    components: {
		LookImages,
        List,
        RightDrawer,
        ApprovalSpeed,
        ApprovalCc,
        ApprovalHeader
    },
    data() {
        return {
			submit_preprocessing:{},
            columns,
            columns_details,
            get_table_list: costList,
            visible: false,
            details: {
                cclist: [],
                customer: {},
                goods: []
            },
            form_data_seo: {
                ...this.$config.form_data_seo,
                list: [
					{
						type:'text',
						name:'customer',
						title:'客户',
						placeholder:'客户名称/ID/地址'
					}
                ]
            }
        }
    },
    methods: {
        handle_details_click({ record }) {
            costInfo({
                data: {
                    id: record.id
                }
            }).then(res => {
                let data = res.data;
                this.details = data;
                this.visible = true;
            })

        }
    }
}
</script>